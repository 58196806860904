<template>
  <div>
    <ul>
      <li
        v-for="itemAttachment in files"
        :key="itemAttachment.ItemAttachmentID"
      >
        <a :href="`${rooturl}${itemAttachment.AttachmentUrl}`">
          <icon-pdf v-if="itemAttachment.ContentType == '.pdf'" />
          {{ itemAttachment.FileName }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import IconPdf from '@/components/icons/IconPDF'
export default {
  components: {
    IconPdf
  },
  props: {
    rooturl: {
      required: true,
      type: String,
      default: () => {
        return ''
      }
    },
    item: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    itemAttachments: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  },

  computed: {
    files: function() {
      if (this.itemAttachments.length > 0) return this.itemAttachments
      return this.item.ItemAttachments
    }
  }
}
</script>

<style></style>
